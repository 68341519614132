.List--nav{
	
	li{
		@include text--navvw;
		padding-bottom:5px;

		li{
	
			padding-left:20px;
		}
		
		

		ul{
			padding-top:5px;
		}
	}

	a{
		border-bottom:1px solid transparent;
		transition: border 0.4s ease, opacity 0.4s ease;

		&.is-active{
			border-bottom:1px solid;
		}
	}

	.List--sub{
		display:none;

	}

	a.is-active + .List--sub,
	.List--sub.is-active{
		display:block;

	}
}

.List--sub{
	li{
		text-transform:none;
		
	}
}

.List--social{
	@extend %u-list--inline;
	padding-bottom:10px;
	li{
		margin-right:10px;
		&:last-child{
			margin-right:0;
		}
	}

	.Icon{
		overflow:visible;
	}
}

.List--masonry{

  .List-sizer,.List-item{

    width:1/3 * 100%;

    @include respond-to('desktop'){
      width:1/4 * 100%;
    }

    @include respond-to('wide'){
      width:1/5 * 100%;
    }

    @include respond-to('mobile'){
      width:100%;
    }
  }

}

.List--legal{
	@extend %u-list--inline;
	li{

		a{
			border-bottom:1px solid transparent;

			&.is-active,
			&:hover{
				border-bottom:1px solid;
			}
		}
		padding-bottom:0;
		&:after{
			content :'|';
			display:inline-block;
			padding:0 5px;
		}

		&:last-child{
			&:after{
				content:'';
				display:none;
			}
		}
	}
}

.List--contact{
	li{
		padding-bottom:5px;
	}
}