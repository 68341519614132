.Misrep{
	position:fixed;
	top:0;
	left:0;
	bottom:0;
	right:0;
	z-index:10;
	background:rgba($Yellow,0.95);
	pointer-events: none;
	opacity:0;
	transition:opacity 0.6s ease;

	&.Misrep--active{
		opacity:1;
		pointer-events:all;
	}
}


.Misrep-close{
	position:absolute;
	top:20px;
	right:20px;
	z-index:2;
	transform: rotate(-45deg);
	*{
		color:black;
	}
}