.Intro{
	position:relative;
	z-index:1;
	background-color:$base-background;
	height:100%;
	width:100%;
	

	cursor:pointer;

}

.Intro-logo{
	z-index:4;
	width:7vw;
	max-width:60px;
	padding-bottom:40px;
}

.Intro-ticker{
	width:100%;
	pointer-events:none;
}
