@keyframes hideMedia{
	0%{
		opacity:1;
	}
	100%{
		opacity:0;
	}
}
@keyframes showMedia{
	0%{
		opacity:0;
	}
	100%{
		opacity:1;
	}
}
@keyframes hideLogo{
	0%{
		opacity:1;
	}
	100%{
		opacity:0;
	}
}
@keyframes showLogo{
	0%{
		opacity:0;
	}
	100%{
		opacity:1;
	}
}
.Item--hero{
	position:absolute;
	@extend %u-layout--fluid;
	
	.slick-active &{
		//transform: translate3d(0,0,0);

		
	}
	.Image{
		background:transparent;
	}

	.Item-media{
		@extend %u-layout--fluid;
		background:black;


		

		a{
			display:block;
			width:100%;
			height:100%;
			transition:opacity 1.5s 0.3s ease;
			opacity:0.55;
			//animation:hideMedia 1.5s 0s ease forwards;

			.slick-active &{
				//transition:opacity 1.5s 0s ease;
				//animation:showMedia 1.5s 0s ease forwards;
				opacity:0.55;
				//transform: translate3d(0,0,0);
			}
		}
	}

	.Item-head{
		position:absolute;
		z-index:4;
		height:90%;
		@include respond-to('desktop'){
			height: 95%;
		}
		width:100%!important;
		pointer-events:none;

		//transition:opacity 1s ease;
		//opacity:0;
		//transform: translate3d(0,20px,0);
		transform: translate3d(0,0,0);
		//opacity:0;
		//animation:hideLogo 1s 0s ease forwards;

		.slick-active &{
			//transition:opacity 1.5s 0.3s ease;
			//opacity:1;
			//animation:showLogo 1.5s 0.3s ease forwards;
			//transform: translate3d(0,0,0);
		}

		.Item-logo{
			width:50%;
			max-width:1000px;
			@extend %u-layout-centre;

			@include respond-to('portrait'){
				width:70%;
				.Image{
					padding-top:66%!important;
				}
			}

			.Image-media{
				opacity:0;
				background-size:contain;
				transition:opacity 1s 0.3s ease;
			}

		
				.Image--loaded .Image-media{
					opacity:1;
				}
		

		}

		.Item-transition.is-visible{
			.Image--loaded .Image-media{
				opacity:1!important;
			}
		}
	}
}
.Item--tile{

	position:relative;
	height:50vw;
	min-height:50vh;
	color:white;

	.Item-media{
		a{
			display:block;
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
		}
		z-index:1;
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		overflow:hidden;
		.Image{
			transition:transform 0.4s ease;
		}

	}

	.Html--no-touch &{
		&:hover{
			.Item-media .Image{
				transform: scale(1.02);
			}
		}
	}

	.Item-head{
		position:absolute;
		top:0;
		left:0;
		right:0;
		z-index:1;
		padding:3vh 0;
		background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);

		.Content h3{
			@include text--headingvw;
			text-align:center;
			text-shadow: 0 0 3px rgba(0,0,0,0.9);
		}
	}

	.Item-body{
		position:absolute;
		bottom:0;
		left:0;
		right:0;
		z-index:1;
		background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
		padding:3vh 0;
		text-align:center;
		padding-top:9vh;
		.Content{
			text-align:center;
			padding-bottom:3vh;
		}

		.Link{
			color:white;
		}

	}

	

}
.Item--news{

	padding:30px;
	padding-top:0;
	padding-bottom:45px;

	@include respond-to('shallow'){
			padding:10px;
		}

		@include respond-to('mobile'){
			padding-left:10px;
			padding-right:10px;
		}

	.Item-head{
		padding:15px 0 5px 0;

		h3{
			padding-bottom:0.5em;
		}
		h4{
			opacity:0.5;
		}

	}



	.Item-body{
		padding-right:20%;

		@include respond-to('shallow'){
			padding-right:0;
		}
	}
	.Image{
		background:$Grey;
	}
	.Content h2{
		padding-bottom:0;
	}

}

.Item--career{

	padding:30px;
	@include respond-to('shallow'){
			padding:10px;
		}

		@include respond-to('mobile'){
			padding-left:10px;
			padding-right:10px;
		}
	padding-top:0;
	padding-bottom:45px;

	.Item-head{
		padding:15px 0 5px 0;

	}

	.Item-foot{
		padding-top:10px;
	}

	.Content h3{
		padding-bottom:0;
	}

	.Item-body{
		padding-right:10%;


	}
	.Image{
		background:$Grey;
	}
	.Content h2{
		padding-bottom:0;
	}

}


.Item--venue,
.Item--overview,
.Item--items{

	.Item-media a{
		display:block;
	}
	.Image-media{
		background-size:contain;
	}

	padding-bottom:40px;
	//text-align:center;

	.Item-content{
		padding-left:3vw;
	}

	.Item-foot{
		padding-top:15px;
	}

	&.Item--overview,
	&.Item--items{
		text-align:center;
		.Item-content{
			padding-left:0px;
		}
	}

	.List{
		padding-top:20px;
	}
}

.Item--items{
	.Image-media{
		background-size:cover;
	}
	.Item-media{
		margin-bottom:20px;
	}
}

.Item--venue{

	@include respond-to('mobile'){
			text-align:center;
		}

	.Item-media{
		position:relative;
	}
	.Item-image1{
		position:relative;
		transition:opacity 0.4s ease;
		z-index:1;
	}
	.Item-image2{
		position:absolute;
		z-index:2;
		top:20px;
		left:40px;
		right:20px;
		bottom:20px;
		opacity:0;
		transition:opacity 0.4s ease;
		opacity:0;

		.Image-media{
			background-size: cover;
		}
	}

	.Item-body{
		padding-top:10px;
		min-height:120px;

		@include respond-to('mobile'){
			min-height:0;
			
			
		}
	}

	&:hover{
		.Item-image1{
			opacity:0;
	
		}
		.Item-image2{
			opacity:1;
		
		}
	}
}

.Item--team{
	padding:30px 5%;

	max-width:300px;
	margin:0 auto;

	.Item-content{
		padding-top:30px;
	}
	.Item-media{
		padding:0 30px;

	}
	.Image-media{
		background-size:contain;

	}

	.Content{
		h3 + h3{
			margin-top:-18px;
		}
	}
}

.Item--template{

	height:100vh;
	position:relative;

	@include respond-to('portrait'){
			height:calc(100vh - 70px);
		}

	@include respond-to('mobile'){
			height:auto;
		}

	.Item-foot{
		
	}

	.Content h3{
		padding-bottom:5px;
		text-align:left;
	}

	.Item-head{
		padding:30px 50px;
		//width:65%;
		max-width: 500px;
		z-index:3;
		position:relative;

		@include respond-to('shallow'){
			width:80%;
		}

		@include respond-to('mobile'){
			padding:30px;
		}

	}


	.Item-cta{
		padding-top:20px;
	}
	.Item-body{
		
		position:absolute;
		top:20%;
		left:0;
		padding:30px 50px;
		width:70%;
		max-width: 500px;

		@include respond-to('shallow'){
			width:90%;
		}

		
	}
	.Item-foot{
		position:absolute;
		bottom:0px;
		left:0%;
		padding:50px;
		z-index:2;
		width:90%;
		max-width: 600px;

		@include respond-to('mobile'){
			padding:30px;
		}

		.Content p{
			text-align: left;
		}

	}

	@include respond-to('mobile'){
		.Item-head,
		.Item-body,
		.Item-foot{
			padding:30px;
			position:relative;
			top:auto;
			left:auto;
			bottom:auto;
			width:auto;
		}
	}
}

.Item--basic{

	min-height:100vh;
	position:relative;

	.Item-foot{
		
	}

	.Content h3{
		padding-bottom:5px;
		text-align:left;
	}

	.Item-head{
		padding:30px 50px;
		z-index:3;
		position:relative;
		max-width:800px;
	

	}

	.Item-body{
		padding:30px 50px;
		z-index:3;
		position:relative;
		padding-top:0;

		.Content{
		
			max-width:400px;
		
		}
	}


}

.Item--article{

	min-height:100vh;
	position:relative;

	.Item-foot{
		
	}

	.Content h3{
		padding-bottom:5px;
		text-align:left;
	}

	.Item-head{
		padding:30px;
		z-index:3;
		position:relative;

		p{
			max-width:400px;
		}

	}

	.Item-body{
		padding:30px;
		z-index:3;
		position:relative;
		padding-top:0;

		.Content{
		
			max-width:400px;
		
		}
	}

	.Item-image{
		padding-bottom:30px;
	}
}

.Item--page,
.Item--teamPage{

	height:100vh;
	position:relative;



	.Item-foot{
		.Content--small{
			text-align:center;
			padding-bottom:20px;
		}
	}

	.Item-head{
		position:absolute;
		top:40px;
		left:50%;
		transform: translateX(-50%);
		width:60%;
		z-index:2;
		text-align:center;

		h1 + h3{
			margin-top:-12px;
		}
		@include respond-to('shallow'){
			//width:80%;
			width:55vh;
		}


	}
	.Item-media{
		
		position:absolute;
		top:42%;
		left:50%;
		transform: translate(-50%,-50%);
		width:70%;

		@include respond-to('shallow'){
			width:55vh;
			top:40%;
			
		}

	}
	.Item-foot{
		position:absolute;
		bottom:50px;
		left:50%;
		transform: translateX(-50%);
		width:70%;
		z-index:2;
		max-width:550px;
		@include respond-to('shallow'){
			width:80%;
		}
	}

	.Item-sub{
		padding-top:5px;
	}

	.Image-media{
		background-size:contain;
	}

	.Item-logo{
		width:50%;
		margin:0 auto;
		
	}

	@include respond-to('portrait'){
		height:calc(100vh - 70px);
	}

	@include respond-to('mobile'){
		height:auto;
		min-height: 100vh;
		padding-bottom:60px;

		.Item-head,
		.Item-foot,
		.Item-media{
			position:relative;
			top:auto;
			left:auto;
			bottom:auto;
			transform:none;
			margin:40px auto;
		}

		.Item-logo{
			width:70%;
		}

		.Item-sub{
			padding-top:5px;
		}
		
	}

	@include respond-to('very-shallow'){
		height:auto;
		min-height: 100vh;
		padding-bottom:60px;
		border-top:1px solid transparent;
		.Item-head,
		.Item-foot,
		.Item-media{
			position:relative;
			top:auto;
			left:auto;
			bottom:auto;
			transform:none;
			margin:40px auto;
		}

		.Item-logo{
			width:70%;
		}

		.Item-sub{
			padding-top:5px;
		}
		
	}
}

.Item--teamPage{
	.Item-media{
		@include respond-to('desktop'){
			position:absolute;
			top:46%;
			left:50%;
			transform: translate(-50%,-50%);
			width:70%;
		}

		@include respond-to('shallow'){
			width:55vh;
			top:48%;
			
		}



	}

	@include respond-to('very-shallow'){
		height:auto;
		min-height: 100vh;
		padding-bottom:60px;

		.Item-head,
		.Item-foot,
		.Item-media{
			position:relative;
			top:auto;
			left:auto;
			bottom:auto;
			transform:none;
			margin:40px auto;
		}

		.Item-logo{
			width:70%;
		}

		.Item-sub{
			padding-top:5px;
		}
		
	}
}

.Item--slider{

		.Content h3{
			padding-bottom:5px;
		}
		.Item-body{
			position:relative;
			padding-bottom:20px;
		}

		.rc-slider-track,
		.rc-slider-rail{
			height:2px;
			border-radius:1px;
		}
		.rc-slider-dot{
			display:none;
		}
		.rc-slider-track{
			background:$Blue;
		}

		.rc-slider-handle{
			border-color:$Blue;
			background:$Blue;
			margin-top:-6px;
		}

		.rc-slider-mark-text{
			
		}

	}