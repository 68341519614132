.Image{
	background:transparent;

	&[data-extension="png"]{
		background:transparent;
	}

	.Template-gallery .Carousel &{
		background:$Blue;
	}

	.Template--article &{
		background:#DDD;
	}
}

.Image-media{
	transition:opacity 0.5s 0.1s ease;
	opacity:0;
	transform: translate3d(0,0,0);
	will-change:opacity;
	z-index:1;
}

.Image-media[style],
.Image-media[src]{
		//animation:fadeIn 1s 0.1s forwards;
		backface-visibility:hidden;
		opacity:1;
	}