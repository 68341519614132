@keyframes scrollLeft {
    from {left: translate3d(0%,0,0);}
    to {transform: translate3d(-50%,0,0);}
}

@keyframes scrollRight {
    from {transform: translate3d(-50%,0,0);}
    to {transform: translate3d(0%,0,0);}
}


.Ticker {

  .Ticker-logo{
    width:10vw;
    

  @include respond-to('portrait'){
    width:25vw;
  }

    position:relative;
    border:1px solid transparent;
    margin:0 20px;
    .Image{
      background:transparent;
    }
    .Image-media{
      background-size:80% auto;
    }
  }

  width: 100%;
  overflow-x:hidden;
  position: relative;
  //left: 50%;
  //right: 50%;
  //margin-left: -50vw;
  //margin-right: -50vw;
  //margin-bottom: 5px;
  //width: 200vw;
  //height: 76px;

  white-space: nowrap;

  ul {
    white-space: nowrap;
    width: auto;
    display:inline-block;
    overflow: hidden;
    
   animation: scrollLeft 60s infinite;
    animation-timing-function: linear;


  }

  &:hover{

      ul{

           animation-play-state: paused;

      }

  }
  ul li {
    display: inline-block;
    margin-right: 0px;

    a{
      text-decoration:none;
    }
   
    /*&:before{

      
        margin:0 20px;

    

          content:'';
          display:inline-block;
          width:15px;
          height:15px;

 

      

    }*/


  }

  
}


