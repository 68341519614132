.Template {
	min-height: 100vh;
	position: relative;
	@include respond-to("tablet-down") {
		padding-top: 50px;
		//padding-bottom:50px;
	}

	@include respond-to("mobile") {
		min-height: calc(100vh - 90px);
	}

	.Template {
		padding-top: 0;
	}

	&.Template--home {
		@include respond-to("tablet-down") {
			padding-top: 0px;
		}
	}
}

.Template-loader {
	padding: 20px;
	text-align: center;
}

.Template--article {
	.Item--article .Item-body .Content {
		max-width: 600px;

		h3 {
			padding-bottom: 12px;
		}
		ul {
			padding-left: 50px;
			padding-bottom: 25px;
			&:last-child {
				padding-bottom: 0;
			}
		}
		li {
			padding-bottom: 0;
			list-style: disc;
		}
	}
	.job-sub {
		display: none;
	}
	.job-info,
	.job-description {
		padding-bottom: 20px;
		border-bottom: 1px solid #eee;
		margin-bottom: 20px;

		&:last-child {
			border: none;
		}
	}
	.job-description {
		padding-bottom: 20px;
	}
}

.Template-loader {
	text-align: center;
	padding: 20px;
}

@include respond-to("tablet-down") {
	.Template-section--desktop {
		display: none !important;
	}
}

@include respond-to("tablet-up") {
	.Template-section--mobile {
		display: none !important;
	}
}

.Template-carousel {
	width: 100%;
	height: 100vh;
	position: relative;

	@include respond-to("mobile") {
		height: calc(100vh - 90px);
	}
}

.Template-section {
	border-bottom: 1px solid $Grey;
	&:last-child {
		border: none;
	}
}

.Template--pagebuilder {
	.Template-head {
		padding: 30px 50px;
		max-width: 500px;

		@include respond-to("mobile") {
			padding: 30px;
		}
	}

	& > * {
		border-bottom: 1px solid $Grey;
		&:last-child {
			border-bottom: none;
		}

		&.Template-filter {
			border-bottom: none;
		}

		@include respond-to("tablet-up") {
			&.Template-head {
				border-bottom: none;
			}
		}
	}

	// Page builder inside another template, no min height
	.Template & {
		padding: 50px 0;
		border-top: 1px solid $Grey;
		min-height: 0;
	}
}

.Template-panel {
	position: fixed;
	top: 0;
	right: 0;
	left: 60px;
	bottom: 0;
	z-index: 7;

	@include respond-to("portrait") {
		top: 50px;
		left: 0;
		.Template-page {
			padding-right: 20px;
		}
	}
	overflow: hidden;
	pointer-events: none;
	.Site--panel & {
		pointer-events: all;
	}

	.Template-close {
		position: absolute;
		top: 10px;
		right: 10px;
		border-radius: 50%;
		border: 1px solid white;
		width: 30px;
		height: 30px;
		cursor: pointer;
		opacity: 1;
		color: white;
		z-index: 9;
		cursor: pointer;
		background: rgba(0, 0, 0, 0.3);

		.Icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(0.8, 0.8);
			transform-origin: 50% 50%;
			text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
		}
	}
	.Template {
		position: absolute;
		background: white;
		left: 0;
		bottom: 0;
		right: 0;
		top: 0;
		@include scrollable;
		transition: transform 0.7s ease;
		transform: translateX(100%);

		@include respond-to("portrait") {
			border-left: 1px solid $Grey;
		}

		.Site--panel & {
			transform: translateX(0%);
			box-shadow: -1px 0px 2px rgba(0, 0, 0, 0.2);
		}
		.Template-gallery {
			position: absolute;
			z-index: 8;

			& > * {
				position: absolute;
			}
		}
	}
}
.Template-cta {
	padding-top: 20px;
	margin-top: 20px;
	border-top: 1px solid $Grey;
}
.Template-overview-list {
	margin: 0 auto;
	width: 90%;
	text-align: center;

	@include respond-to("desktop") {
		&:before {
			display: inline-block;
			width: 1px;
			margin-left: -1px;
			height: 100vh;
			vertical-align: middle;
			content: "";
		}

		& > * {
			vertical-align: middle;
		}
	}

	@include respond-to("tablet-down") {
		width: 70%;
	}
}

.Template-gallery,
.Template-map {
	position: relative;
	height: 100vh;
	width: 100%;

	@include respond-to("desktop") {
		height: 100%;
		width: 50%;
		position: absolute;

		bottom: 0;
		right: 0;
		z-index: 3;
		margin-left: auto;

		& > * {
			height: 100vh;
			position: sticky;
			top: 0;
			left: 0;
		}
	}
}

.Template-gallery {
	background: $Blue;
}

.Template-map {
	@include respond-to("tablet-down") {
		height: 60vh;
	}

	.Map {
		width: 100%;
		height: 100%;
	}
	@include respond-to("desktop") {
		z-index: 4;
		& > * {
			overflow: hidden;
		}
		pointer-events: none;

		.Template--map & {
			pointer-events: all;
		}

		.Map {
			transition: transform 1s ease;
			transform: translateX(100%);

			.Template--map & {
				transition: transform 0.7s ease;
				transform: translateX(0%);
			}
		}
	}
}

.Template-no-results {
	padding: 10vw;

	@include respond-to("portrait") {
		height: 40vh;
	}
}

.Template-filter {
	z-index: 3;

	padding: 30px 50px;

	@include respond-to("tablet-down") {
		position: relative;
		padding-top: 0;
	}

	@include respond-to("mobile") {
		padding: 30px;
	}

	width: 100%;

	@include respond-to("desktop") {
		position: absolute;
		top: 0px;
		right: 0px;
		width: 300px;
	}
}

.Template--private-events {
	.Template-sticky-wrapper {
		@include respond-to("desktop") {
			height: 100%;
			position: absolute;
			width: 50%;
			pointer-events: none;
		}
	}

	.Template-filter-title,
	.Template-filter-close {
		display: none;
	}

	@include respond-to("tablet-down") {
		.Template-filter {
			position: fixed;
			top: 50px;
			left: 0;
			bottom: 0;
			right: 0;
			border-left: 1px solid $Grey;
			background: white;
			transition: transform 0.7s ease;
			pointer-events: none;
			transform: translateX(100%);
			padding-top: 30px;
			.Site--filters & {
				transform: translateX(0);
				pointer-events: all;
			}
		}

		.Template-filter-title,
		.Template-filter-close {
			display: block;
		}
	}

	@include respond-to("tablet") {
		.Template-filter {
			left: auto;
			width: 400px;
		}
	}

	.Link--filters {
		@include respond-to("desktop") {
			display: none;
		}
	}

	@include respond-to("portrait") {
		padding-bottom: 50px;
	}

	.Template-head {
		padding: 30px 50px;
		max-width: 500px;

		@include respond-to("mobile") {
			padding: 30px;

			.Link--button {
				min-width: 250px;
			}
		}

		.Content {
			padding-bottom: 20px;
		}
		@include respond-to("desktop") {
			max-width: 600px;
		}
	}

	@include respond-to("desktop") {
		.Template-filter {
			position: absolute;

			top: auto;
			bottom: 0;
			left: 0;
			right: auto;
			pointer-events: none;
		}

		.Template-dropdown:last-child {
			.Dropdown-body {
				top: auto;
				bottom: 100%;
			}
		}

		.Template-dropdown--long {
			.Dropdown-body {
				top: auto;
				bottom: 100%;
			}
		}

		.Template-sticky {
			height: 100vh;

			position: sticky;
			top: 0;
			left: 0;
			pointer-events: none;

			& > * {
				pointer-events: all;
			}
		}
	} // End desktop

	.Template-body {
		padding: 20px;

		@include respond-to("desktop") {
			margin-left: auto;
			width: 50%;
		}
	}
}

.Template--news {
	@include respond-to("portrait") {
		padding-bottom: 50px;
	}

	.Template-head {
		padding: 30px 50px;
		max-width: 500px;

		@include respond-to("mobile") {
			padding: 30px;
		}
	}

	.Template-body {
		padding: 20px;

		@include respond-to("shallow") {
			padding: 20px 40px;
		}
	}

	@include respond-to("mobile") {
		[data-grid-of] {
			margin-left: 0;
		}
		.List-item {
			padding: 0;
		}
	}
}

.Template-sticky-wrapper {
	position: relative;
}

.Template-gallery.Template-scroller-wrapper {
	@include respond-to("portrait") {
		display: none;
	}
}

.Template-scroller {
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: $Blue;

	.Template-scroller-track {
		white-space: nowrap;
		width: auto;
		height: 100%;
		.Template-scroller-item {
			height: 100%;
			display: inline-block;
			overflow: hidden;
			width: 50vw;
			position: relative;
		}
	}
}
.Template-intro {
	height: 100vh;
	@extend %u-layout-middle;
	padding: 0 10vw;
	text-align: center;
	border-bottom: 1px solid $Grey;

	@include respond-to("portrait") {
		height: auto;
		padding: 20vh 15vw;
		border-bottom: 1px solid $Grey;
	}
}

.Template-features {
	margin: 0 auto;
	max-width: 1600px;
	padding: 30px 20px;

	.Template-features-list {
		text-align: center;
	}
}

.Template-page {
	@include respond-to("desktop") {
		width: 50%;
	}
}
