.Map{
	
}

.Map-marker{
	width:200px;
	height:200px;
	transform: translate(-50%,-50%);
	pointer-events:none;
}

.Map-pin{
	cursor:alias!important;
	width:60px;
	height:60px;
	border-radius:50%;
	padding:5px;
	position:absolute;
	top:20px;
	left:50%;
	transform: translateX(-50%);
	pointer-events:all;
	&:after{
		content:'';
		border-left:1px solid;
		border-right:1px solid;
		height:20px;
		left:50%;
		top:100%;
		position:absolute;
		width:0;
		display:block;
	}

	.Map-pin-logo{
		width:70%;
		position:absolute;
		top:50%;
		left:50%;
		transform: translate(-50%,-50%);
	}
	.Image-media{
		background-size:contain;
	}
}