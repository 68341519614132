.Dropdown{

	
	position:relative;
	cursor:pointer;
	max-width:300px;
	
	@include respond-to('mobile'){
		max-width:350px;
	}
	margin-bottom:15px;

	
	@include text--introvw;
	text-transform:uppercase;
	line-height:160%;
	li{
		@include text--introvw;
		text-transform:uppercase;
		line-height:160%;
	}
	
}

@keyframes fadeIn{
	0%{
		opacity:0;
	}
	100%{
		opacity:1;
	}
}
.Dropdown-arrow{
	position:absolute;
	top:0;
	right:0;
	height:100%;
	width:30px;
	transition:transform 0.4s ease;
	.Icon{
		position:absolute;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
		width:15px;
		height:19px;
		font-size:11px!important;
		line-height:18px!important;
		&:before{
			font-size:11px!important;
			line-height:100%!important;
			text-align:center;
		}
	}
}
.Dropdown-head{
	padding:6px 10px 5px 10px;
	border:1px solid black;
}
.Dropdown-body{
	position:absolute;
	top:100%;
	left:0;
	width:100%;
	display:none;
	margin-top:-1px;
	background:white;
	li{
		background:white;
		opacity:0;
		position:relative;
		z-index:1;

		&:hover{
			z-index:2;
		}
	}
}

.Dropdown.is-active{
	z-index:5;
	.Dropdown-head{
		background:$Black;
		color:white;
		
		
	}
	.Dropdown-arrow{
		color:white;
		transform: rotate(-45deg);
	}
	.Dropdown-body{
		display:block;
		animation: fadeIn 0.5s ease forwards;

		li{
			
			animation:fadeIn 0.7s 0.14s ease forwards;
		}
		li:nth-child(1){
			animation:fadeIn 0.4s 0s ease forwards;
		}
		li:nth-child(2){
			animation:fadeIn 0.41s 0.05s ease forwards;
		}
		li:nth-child(3){
			animation:fadeIn 0.43s 0.1s ease forwards;
		}
		li:nth-child(4){
			animation:fadeIn 0.46s 0.12s ease forwards;
		}
		li:nth-child(5){
			animation:fadeIn 0.5s 0.13s ease forwards;
		}
		
	}
}

.Dropdown-body a{

	display:block;
	padding:5px 10px;
	opacity:1;
	border:1px solid;
	margin-top:-1px;
	background:white;
	transition:transform 0.4s $easeOutBack;
	position:relative;


	&:hover{
		transform:rotate(-2deg);
		
	}

	&.is-active{
		//pointer-events:none;
		color:$Grey;
		border-color:$Black;
	}
}