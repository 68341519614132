$burger_size : 20px;
$dot_size : 4px;

$offset_top : (($burger_size/2) + ($dot_size / 2)) * -1;
$offset_bottom : (($burger_size/2) - ($dot_size / 2)) ;

.Burger{
	position:relative;
	cursor:pointer;
	width:40px;
	height:40px;
}

.Burger-body{
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	width:$burger_size;
	height:$burger_size;
	transition:transform 0.5s 0.15s $easeOutBack;
	transform-origin:50% 50%;
	& > *{
		position:absolute;
		transition:transform 0.5s ease, opacity 0.3s;

		background:white;
		width:$dot_size;
		height:$dot_size;
		border-radius: 50%;
	}
}

.Burger-centre{
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);

}

.Burger-tl{
	top:50%;
	left:0%;
	transform:translate(-50%,-50%);
}

.Burger-tr{
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	opacity:0;
}

.Burger-br{
	top:50%;
	left:100%;
	transform:translate(-50%,-50%);
}

.Burger-bl{
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	opacity:0;
}

.Site--menu{

	.Burger-body{

		transform: translate(-50%,-50%) rotate(-45deg);
		& > *{
		
			transition:transform 0.5s 0s $easeOutBack, opacity 0s 0s;
		}
	}
	.Burger-tl{
	
		//transform:translate(-50%,$offset_top);
	}

	.Burger-tr{
		
		transform:translate(-50%,$offset_top);
		opacity:1;
	}

	.Burger-br{
		
		//transform:translate(-50%,$offset_bottom);
	}

	.Burger-bl{
	
		transform:translate(-50%,$offset_bottom);
		opacity:1;
	}
}