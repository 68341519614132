a[target="_blank"]{
	cursor:alias;
}
.Link--callToAction{
	display:block;
	padding:40px;
	text-transform:uppercase;
	@include respond-to('desktop'){
		padding:5vw;
	}
	@include text--headingvw;
	line-height:90%;
		
	text-align:center;
	
		position:relative;
		z-index:1;
		&:before{
			content:'';
			display:block;
			position:absolute;
			width:100%;
			height:100%;
			top:0px;
			left:0;
			
			background:$Black;
			border:2px solid transparent;
			transition:transform 0.3s ease;
			transform-origin: 50% 50%;
			z-index:-1;
			transform: scaleY(0);
		}

		&:hover{
			color:white;
			&:before{
				transform: scaleY(1);
			}
		}
}

.Link--button,
.Link--submit{
	@extend %u-link--button;
	cursor:pointer;
}

.Link--logout,
.Link--dummy{
	margin-top:20px;
	cursor:pointer;
}

.Link--booking{
	animation: fadeIn 0.5s ease forwards;
	@include text--introvw;
	text-transform:uppercase;
	margin-top:10px;
	display:block;
	text-align:center;
	min-width:100px;
	background:white;
	padding:0 20px;
	border-radius:5px;
	height:40px;
	line-height:40px;
	position:relative;
	border-top-left-radius:0px;
	border-bottom-left-radius:0px;
	transition:color 0.5s ease, background 0.2s 0s ease;
	box-shadow:0 0 2px rgba(0,0,0,0.5);
	span{
		position:relative;
		z-index:2;
		white-space: nowrap;
		display:inline-block;

	}
	&:hover{
		color:white;
		background:transparent;
		transition:color 0.5s ease, background 0.2s 0.2s ease;
		&:after{
			transform:translateY(-50%) scaleX(1);
		}
	}

	&:after{
		content:'';
		display:block;
		width:100%;
		height:100%;
		background:$Blue;
		position:absolute;
		left:0;
		top:50%;
		border-top-right-radius:5px;
		border-bottom-right-radius:5px;
		transform:translateY(-50%) scaleX(0);
		transform-origin: 0% 50%;
		z-index:1;
		transition:transform 0.2s ease;
	}
	&:before{
		content:'';
		display:block;
		width:40px;
		height:40px;
		
		position:absolute;
		top:0;
		right:100%;
		border-top-left-radius:5px;
		border-bottom-left-radius:5px;
		background:url(/ui/img/logo.svg) center center no-repeat;
		background-color:$Blue;
		background-size:70% auto;

	}


}