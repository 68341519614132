.LightBox{
	position:fixed;
	top:0;
	left:0;
	bottom:0;
	right:0;
	z-index:10;
	background:rgba(0,0,0,0.9);
	pointer-events: none;
	opacity:0;
	transition:opacity 0.6s ease;

	&.LightBox--active{
		opacity:1;
		pointer-events:all;
	}
}

.LightBox-image{
	position:absolute;
	top:10%;
	right:10%;
	bottom:10%;
	left:10%;
	z-index:1;
	.Image{
		background:transparent;
	}

	.Image-media{
		background-size:contain;
	}
}

.LightBox-close{
	position:absolute;
	top:20px;
	right:20px;
	z-index:2;
	transform: rotate(-45deg);
	*{
		color:white;
	}
}

.LightBox-prev{
	position:absolute;
	left:20px;
	top:50%;
	transform: translateY(-50%);
	z-index:2;
	*{
		color:white;
	}
}

.LightBox-next{
	position:absolute;
	right:20px;
	top:50%;
	transform: translateY(-50%);
	z-index:2;
	*{
		color:white;
	}
}