/**
 * Settings
 *
 * Core defaults
 */

/**
 * Breakpoints
 *
 * Hold our breakpoint aliases and conditions in a list. These can be invoked
 * later on via the `respond-to()` mixin found in _mixins.scss.
 */

$breakpoints: (
  "mobile" "(max-width: 745px)",
  "tablet" "(min-width: 746px) and (max-width: 1023px)",
  "tablet-up" "(min-width: 746px)",
  "tablet-down" "(max-width: 1023px)",
  "desktop" "(min-width: 1024px)",
  "desktop-plus" "(min-width: 1200px)",
  "wide" "(min-width: 1400px)",
  "max" "(min-width: 2000px)",
  "shallow" "(min-width: 1024px) and (max-height: 1000px)",
  "very-shallow" "(min-width: 746px) and (max-height: 750px)",
  "fix-menu-footer" "(min-height: 1800px)",
  "iphone5"
    "(min-width: 320px) and (max-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation: portrait)",
  "portrait" "(orientation: portrait)"
);

/**
 * Base Grid & Spacing values
 */

$base-vw: 1024;
$base-vh: 768;
$base-padding: (
  "*": 20px,
  "mobile": 10px,
);
$base-grid-gutter: $base-padding;
$base-max-width: 1200px;
$base-max-text-width: 700px;
$base-max-dialog-width: 700px;

/**
 * Base Type Settings
 */

$base-font-size: 16px;
$base-line-height: 22px;
$base-letter-spacing: 0;

$base-font-family: "Noe Text", sans-serif;
$base-font-family-bold: "Noe Text", sans-serif;
$base-font-family-alt: "SangBleu Sunrise", serif;
$base-text-align: left;

$base-text-families: (
  "sans": (
    "weights": (
      "regular": $base-font-family,
      "bold": $base-font-family-bold,
    ),
    "sizes": (
      "micro": 10px,
      "xs": 10px,
      "small": 10px,
      "default": $base-font-size,
      "large": 18px,
      "hero": 36px,
    ),
  ),
  "alt": (
    "weights": (
      "regular": $base-font-family-alt,
      "bold": $base-font-family-alt,
    ),
    "sizes": (
      "micro": 8px,
      "xs": 12px,
      "small": 14px,
      "default": $base-font-size,
      "large": 32px,
      "hero": 36px,
    ),
  ),
);

/*

Palette

*/

$Teal: #375a64;
$Yellow: #e6d200;
$Pink: #ff785a;
$Gold: #be9a5e;

$Blue: #0b1e35;
$Black: #232121;
//$Black: #111123;
$Grey: #dad8d6;

$base-text-applications: (
  "intro": (
    "base": "alt--regular--small",
    "letter-spacing": 0.1em,
  ),
  "heading": (
    "base": "alt--bold--hero",
    "letter-spacing": 0.1em,
    "line-height": 120%,
  ),
  "body": (
    "base": "sans--regular--default",
    "letter-spacing": -0.05em,
  ),
  "nav": (
    "base": "alt--bold--default",
    "letter-spacing": 0.1em,
    "text-transform": uppercase,
  ),
  "small": (
    "base": "sans--regular--small",
    "letter-spacing": 0.01em,
  ),
  "smallAlt": (
    "base": "alt--regular--small",
    "letter-spacing": 0.1em,
    "text-transform": uppercase,
  ),
);

$base-text-weights: ();
$base-text-sizes: ();

/**
 * Base Colours
 */

$base-color: black;
$base-background: #ffffff;
$base-link-color: $base-color;
$base-link-opacity-hover: 1;
$base-active-color: $base-color;

$base-overlay-background: #000;
$base-overlay-transparency: 0.85;

$base-ui-color: #ccc;
$base-ui-color-alt: #333;

$base-placeholder-color: rgba(0, 0, 0, 0.2);
$base-placeholder-opacity: 0.1;

$base-selection-background: rgba(0, 0, 0, 1);
$base-selection-color: #fff;

$base-image-color: #eee;
$base-image-color-loaded: #eee;
$base-image-fade: 2s;
$base-image-delay: 0.25s;
$base-image-ease: ease;

/**
 * Base Icons
 */

//$base-icons : "cross" "arrow-right" "arrow-left" "arrow-up" "arrow-down" "play" "search" ;
$base-icons: "arrow-right" "arrow-left";
$base-icon-extension: "svg";
$base-icon-size: 16px;

$base-icon-glyphs: (
  "deliveroo": "\e900",
  "walk-in": "\e90f",
  "eat-in": "\e90f",
  "book-now": "\e912",
  "book-online": "\e912",
  "reservations": "\e912",
  "order-swift": "\e910",
  "take-away": "\e910",
  "rice": "\e911",
  "search": "\e90e",
  "phone": "\e901",
  "arrow-left": "\e907",
  "arrow-right": "\e908",
  "menu": "\e909",
  "play": "\e90a",
  "plus": "\e90b",
  "cross": "\e90c",
  "email": "\e90d",
  "map": "\e902",
  "pinterest": "\e903",
  "twitter": "\e904",
  "facebook": "\e905",
  "instagram": "\e906",
);

/**
 * Base Cursors
 */

$base-cursor-prev: pointer;
$base-cursor-next: pointer;
$base-cursor-play: pointer;
$base-cursor-pause: pointer;

/**
 * Base Sections (Sections being regions that allow you to control core styling)
 * The keys in this array will be used as modifiers (.Section--{key}) with the config settings provided
 * At a config level Sections can define:
  - background color
  - foreground color,
  - heading typeface,
  - body typeface
  - padding for the .Section-body
  - max width for the .Section-body
  - colour for images
  - colour links
  - colour for active items
 */

$base-sections: ();

/**
 * Top level hooks
 */

$base-touch-class: ".Html--touch";
