.Content{

	h4{
		@include text--smallvw;
		
		padding-bottom:0.7em;

		a{
			@extend %u-link--button;
			margin-bottom:15px;

		}

		& + h3{
			margin-top:20px;
		}
	}

	h6{
		@include text--legal;
	}

	h3{
		@include text--introvw;
		text-transform:uppercase;
		padding-bottom:20px;

		
		@include respond-to('shallow'){
			padding-bottom:12px;
		}
		line-height:160%;
		b,strong{
			font-family:inherit;
		}

	}

	h1,h2{
		@include text--headingvw;
		letter-spacing:0.1em;
		text-transform:uppercase;
		padding-bottom:30px;
		b,strong{
			font-family:inherit;
		}

		.Site-read &{
			letter-spacing:0;
			text-transform:none;
		}
		
	}
	.page, p,li{
		@include text--bodyvw;
		
		padding-bottom:20px;
		&:last-child{
			padding-bottom:0;
		}

		strong{
			font-weight:bold;
		}
	}



	p + h4 a{
		transform:translateY(-10px);
		margin-bottom:0;
	}

	a{
		position:relative;
		border-bottom:1px solid;

		&:hover{
			border-color:transparent;
		}
	}

	table{
		p{
			
		}

		tr{

			td{
				padding:6px;
				border-top:1px solid $Grey;
				vertical-align:top;
				text-align:left;
				@include text--bodyvw;

			}

		}

		thead{
			td{

				border-top:3px solid $Grey;

			}
			p{

				padding:0;

			}
		}

		border-bottom:1px dotted $Grey;
		margin-bottom:24px;
		padding-bottom:24px;

	}

	ol{

		margin-left:24px;

		@include respond-to('mobile'){

			margin-left:16px;
			padding-left:16px;

		}

	}
	ol li{
		list-style-type:decimal-leading-zero;
		padding-bottom:12px;
		ol li{

			list-style-type:lower-roman;

		}
	}

	td{
		@include respond-to('mobile'){
			display:block;
			&:first-child{
				border-width:3px;
			}
		}
	}


	
	
}

.Content--intro{
	max-width:1100px;
	margin:0 auto;
	h1,h2,h3,h4,h5,h6,p{
		text-align:center;
		
		@include text--headingvw;

		line-height:160%;
		//max-width:1000px;
		margin:0 auto;

	}

	
}

.Content--intro_text{

	max-width:850px;
	margin:0 auto;
	h1,h2,h3,h4,h5,h6,p,.page{
		padding-top:20px;
		text-align:center;
		
		//width:80%;
		line-height:160%;
		margin:0 auto;

	}

	h2{
		text-transform:none;
		letter-spacing:0;
	}

	p,.page{
		font-size:18px;
		@include respond-to('tablet-down'){
			font-size:16px;
		}
	}

	
}

.Content--page{

	h1,h2,h3,h4,h5,h6{
		text-align:center;
		padding-bottom:20px;

	}

	p{
		//text-indent:3em;
		padding-bottom:10px;
		//padding-bottom:0;
		text-align: justify;
			text-justify: inter-word;

		&:first-of-type{
			text-indent:0;
		}
	}

	p + h4 a{
		transform:none;
		margin-top:20px;
		margin-bottom:0;
	}
}

.Content--address{

			h1,h2,h3,h4,h5,h6,p,li{
				text-align:center;
				@include text--introvw;
				letter-spacing:0.1em;
				text-transform:uppercase;
				padding-bottom:10px;
				line-height: 180%;

			}

			
}

.Content--opener{

			h1,h2,h3,h4,h5,h6,p,li{
				
				@include text--introvw;
				letter-spacing:0.1em;
				text-transform:uppercase;
				padding-bottom:10px;
				line-height: 180%;

				&:last-child{
					padding-bottom:10px;
				}

			}

			
}


.Content--downloads{
	& > *{
		padding-bottom:1em;
	}
}


.Content--caption{
	h6{text-align:left;padding-bottom:15px;}

}


.Content--small,
.Content--legal,
.Content--credit{
	position:relative;
	padding-bottom:12px;
	p,li{
		@include text--smallvw;
		line-height:150%;
		padding-bottom:0em;
		padding-left:0;
	}
}

.Content--venueLocation{
	position:relative;
	padding-bottom:12px;
	p,li{
		@include text--smallvwAlt;
		line-height:150%;
		padding-bottom:0em;
		padding-left:0;
	}
}

.Content--legal{
	text-align:center;
	p,ul{
		display:inline-block;
	}

	p + ul{
		padding-left:10px;
	}
}

.Content--credit{
	padding-top:40px;
	opacity:0.2;
}
