.Header{
	position:relative;
	height:100%;
	width:100%;
}

.Header-text{
	position:absolute;
	top:50%;
	left:50%;
	transform: translate(-50%,-50%);
	@include text--navvw;

	a{
		color:white;
		white-space: nowrap;
		display:block;
	}

	@include respond-to('desktop'){
		
		transform: translate(-50%,-50%) rotate(-90deg);
		transform-origin: 50% 50%;

	}
}

.Header-burger{
	position:absolute;
	

	@include respond-to('tablet-down'){
		right:5px;
		top:50%;
		transform:translate(0,-50%);
		
	}

	@include respond-to('desktop'){
		top:5px;
	left:50%;
	transform:translate(-50%,0);
	}

}

.Header-logo{
	position:absolute;

	@include respond-to('tablet-down'){
		left:10px;
		top:50%;
		transform:translate(0,-50%);
		width:30px;
	}

	@include respond-to('desktop'){
		bottom:20px;
		width:60%;
		left:50%;
		transform:translate(-50%,0);
	}
}