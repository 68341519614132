html .Cookie-message{
	bottom:10px;
	right:10px;
}
#preloader{
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background:$Blue;
	z-index:10;

	&:after{
		content:'';
		display:block;
		width:50px;
		height:50px;
		background:url(/ui/img/logo.svg) center center no-repeat;
		background-size:99% auto;
		position:absolute;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
		animation:pulse 1s infinite;
	}

	opacity:1;
	transition:opacity 1s 0.5s ease;
	pointer-events:none;

	.Html--open &{
		opacity:0;
	}
}



.Site-components{
	padding:20px;
	background:#EEE;
	box-shadow:0 0px 2px rgba(0,0,0,0.25);
	margin-bottom:50px;

	& > h1{
		font-family:sans-serif;
		font-size:13px;
		color:#222;
		border-top:3px solid #222;
		padding:5px 0;
		font-weight:normal;
		text-transform:uppercase;
		letter-spacing:0.08em;
		margin-bottom:20px;
	}

	& > h2{
		font-family:sans-serif;
		font-size:10px;
		color:#222;
		border-top:1px solid #222;
		padding:5px 0;
		text-transform:uppercase;
		font-weight:normal;
		letter-spacing:0.04em;
		margin-bottom:60px;
	}

	& .Site-components-heading3{
		font-family:sans-serif;
		font-size:10px;
		color:#222;
		border-top:1px solid #222;
		padding:5px 0;
		text-transform:none;
		font-weight:normal;
		letter-spacing:0.04em;
		margin-bottom:20px;
		opacity: 0.5;
	}
}

.Loader{
	position:fixed;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	transition:opacity 0.2s ease;
	pointer-events:none;
	z-index:9;
	.Html--loaded &{
		opacity:0;

	}
	span{
	display:block;
	@include hide-text;
	background:rgba(0,0,0,0.2);
	border-radius:50%;
	width:16px;
	height:16px;
	animation: pulse 1s infinite;
	display:none;
	}
}

html,body,.Site{
	overflow:visible;
}

html{
	overflow:hidden;
	&.Html--open{
		overflow:visible;
	}
}

/*

	Intro

*/
.Site-intro{
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index:10;
	transition:opacity 1s ease;
	cursor: pointer;

	.Site--open &{
		opacity:0;
		pointer-events:none;
	}
}

/*

	Head

*/
.Site-head{
	position:fixed;
	top:0;
	left:0;
	background:$Blue;

	@include respond-to('tablet-down'){
		height:50px;
		right:0;
		z-index:5;
	}

	@include respond-to('desktop'){
		width:60px;
		
		z-index:5;
		bottom:0;
		border-right:1px solid rgba(255,255,255,0.1);
	}
}

/*

	Menu

*/
/*
	Site-menu
*/
.Site-menu{
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index:4;
	transition:transform 0.6s ease;
	background:#FFFFFF;
	box-shadow:0 0 2px rgba(0,0,0,0.3);
	@include respond-to('desktop'){
		right:auto;
		width:400px;
	
		transform: translate3d(-100%,0,0);
	}

	@include respond-to('tablet-down'){
		transform: translate3d(100%,0,0);
		width:75%;
		max-width:400px;
		left:auto;


	}

	

	.Site--menu &{
		transition:transform 1s $easeOutExpo;
		transform: translate3d(0,0,0);
	}

	
}

.Site-menu-close{
	
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index:3;
	pointer-events:none;

	.Site--menu &{
		pointer-events:all;
	}
}

.Site-template{
	margin-bottom:38vw;


	@include respond-to('portrait'){
		margin-bottom:70vh;
	}

	position:relative;
	z-index:1;
	background:white;
	//transition:filter 1.5s ease;
	//will-change:filter;

	/*.Site--menu &{
		filter:blur(5px);
	}*/
	@include respond-to('desktop'){
		padding-left:60px;
	}

	
}

/*

Book

*/

.Site-book{
	position:absolute;
	top:0;
	right:0;
	padding:0px;
	min-width:200px;
	height:100%;
	pointer-events:none;
	z-index:8;

	@include respond-to('mobile'){
		right:50%;
		transform:translateX(60%);


	}

	.Site-book-sticky{
		display:block;
		position:sticky;
		height:100vh;
		width:100%;
		right:0;
		top:0;
		
		z-index:4;

		@include respond-to('mobile'){
			
			.Site--home &{
				height:calc(100vh - 100px);
			}
		}

	}

	.Site-book-button{
		position:absolute;
		bottom:0px;
		right:0;
		padding:30px;
		width:100%;
		pointer-events:all;
	}
}


/*

Footer

*/
.Site-foot{
	background:$Black;
	position:fixed;
	z-index:0;
	bottom:0;
	left:0;
	right:0;
}

/*
	
Popup message

*/

.Site-message{
	
	.Site-message-content{
		position:fixed;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
		
		background:$Blue;
		z-index:99;
		padding:20px;
		
		box-shadow:0 0 2px rgba(0,0,0,0.3);
		
		padding-top:70px;
		padding-bottom:50px;


		width:80%;
		max-width:500px;
		border-radius:5px;

		*{
			color:white;
		}
	}

	.Site-message-close{
		position:absolute;
		top:10px;
		right:10px;
		border-radius:50%;
		border:1px solid;
		width:30px;
		height:30px;
		cursor:pointer;
		opacity:1;
		.Icon{
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%) scale(0.8,0.8);
			transform-origin:50% 50%;
		}
	}

	transition:opacity 0.4s ease;
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index:99;

	.Content{
		text-align:center;
		margin:0 auto;

		
		padding:20px 0;
		margin:0 40px;

		

		h1,h2,h3,h4,h5{
			@include text--smallvw;
			font-family:$base-font-family-alt;
			text-transform:uppercase;
			margin:0;
			padding:0;
			&:after{
				content:'';
				opacity:0.25;
				display:block;
				border-bottom:1px solid;
				width:100%;
				margin:0 auto;
				padding-top:1em;
			}
			padding-bottom:1em;
		}

		p,li{
			@include text--smallvw;
			margin:0;
			padding:0;
			padding-bottom:1em;
		}
	}

	.Site--noMessage &{

		opacity:0;
		pointer-events:none;

	}

	.Site-message-bg{

		position:fixed;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background:red;
		z-index:88;
		background:rgba(255,255,255,0.7);

	}

}

/*
	
Booking Widget

*/

.Site-widget{
	
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index:5;

	@include respond-to('portrait'){
		@include scrollable();
	}

	.Site-widget-box{
		position:fixed;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
		
		background:white;
		z-index:99;
		padding:20px;
		
		box-shadow:0 0 20px rgba(0,0,0,0.3);
		
		padding-top:50px;


		width:80%;
		max-width:1000px;
		border-radius:5px;
		height:70vh;

		@include respond-to('portrait'){
			width:90%;
			position:relative;
			top:auto;
			left:auto;
			transform:none;
			margin:20px auto;
			height:auto;
			padding:50px 10px;
		}
		
	}

	.Site-widget-image{
		padding-bottom:20px;
	}

	.Site-widget-iframe{
		display:inline-block;
		width:50%;
		height:100%;
		border:1px solid $Grey;
		position:relative;
		&:after{
			content:'Loading availability...';
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			z-index:1;
		}

		@include respond-to('portrait'){
			width:100%;
			height: 700px;
			margin-bottom:20px;
		}

		iframe{
			width:100%;
			height:100%;
			position:relative;
			z-index:2;
		}
		vertical-align:top;
	}

	.Site-widget-content{
		display:inline-block;
		width:50%;


		
		padding-left:20px;

		@include respond-to('portrait'){
			width:100%;
			padding-left:0;
		}
		vertical-align:top;

		.Content{
			padding-right:60px;
		}
		
	}
	.Site-widget-close{
		position:absolute;
		top:10px;
		right:10px;
		border-radius:50%;
		border:1px solid;
		width:30px;
		height:30px;
		cursor:pointer;
		opacity:1;
		.Icon{
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%) scale(0.8,0.8);
			transform-origin:50% 50%;
		}
	}

	transition:opacity 0.4s ease;
	pointer-events:none;
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index:99;
	opacity:0;

	.Site--widget &{
		opacity:1;
		pointer-events:all;
	}

	.Content{
		
	


		h1,h2,h3{
			@include text--bodyvw;
			font-family:$base-font-family-alt;
			font-weight:normal;
			text-transform:uppercase;

			letter-spacing:0.1em;
			margin:0;
			padding:0;
			padding-bottom:1em;
			strong{
				font-weight:normal;
			}
		}

		p,li{
			@include text--bodyvw;
			margin:0;
			padding:0;
			padding-bottom:1em;
		}

		p + h3{
			padding-top:10px;
		}
	}

	

	.Site-widget-bg{

		position:fixed;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background:red;
		z-index:88;
		background:rgba(255,255,255,0);

	}

}

.Site-read{
	pointer-events:none;

	.Site--reading &{
		
		pointer-events:all;
	}

	.Site-read-bg{
		position:fixed;
		top:0;
		left:0;
		right:0;
		bottom:0;
		z-index:2;
		background:rgba(255,255,255,0.5);
		transition:opacity 1s ease;
		opacity:0;
		.Site--reading &{
			opacity:1;
		}
	}
	.Site-read-close{
		position:absolute;
		top:20px;
		right:20px;
		border-radius:50%;
		border:1px solid;
		width:30px;
		height:30px;
		cursor:pointer;
		opacity:1;
		z-index:5;
		.Icon{
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%) scale(0.8,0.8);
			transform-origin:50% 50%;
		}
	}
	.Site-read-content{
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		@include scrollable;
		z-index:4;

		.Item-head{
			padding-right:80px!important;
		}
	}
.Site-read-article{
	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:50%;

	@include respond-to('portrait'){
		left:20%;
	}
	@include respond-to('mobile'){
		left:50px;
	}
	background:white;
	z-index:5;
	box-shadow:-1px 0 2px rgba(0,0,0,0.2);
	transition:transform 0.8s ease;
	transform: translateX(100%);

	.Site--reading &{
		transform: translateX(0);
	}

	.Template{
		padding-top:0;
	}
}
}

