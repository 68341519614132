// Base styles are in settings file
@mixin text--smallvw{
	@extend %u-text--small;
	@include respond-to('desktop'){
		font-size:11px;
	}

	font-size:11px;

	@include respond-to('mobile'){
		font-size:10px;

	}
}

@mixin text--smallvwAlt{
	@extend %u-text--smallAlt;
	@include respond-to('desktop'){
		font-size:8px;
	}

	letter-spacing:0.05em;
	font-size:8px;

	@include respond-to('mobile'){
		font-size:6px;

	}
}

@mixin text--legal{
	
	@include respond-to('desktop'){
		font-size:7/1024 * 100vw;
	}
	@include respond-to('tablet-down'){
		font-size:8px;
	}
}

@mixin text--introvw{
	@extend %u-text--intro;
	@include respond-to('desktop'){
		font-size:11px;
	}

	font-size:11px;

	@include respond-to('mobile'){
		font-size:10px;

	}
	line-height:120%;
}

@mixin text--headingvw{
	@extend %u-text--heading;
	@include respond-to('desktop'){
		font-size:32px;
	}

	font-size:32px;

	@include respond-to('shallow'){
		font-size:26px;
	}

	@include respond-to('mobile'){
		font-size:20px;

	}
}

@mixin text--navvw{
	@extend %u-text--nav;

	@include respond-to('desktop'){
		font-size:12px;
	}

	@include respond-to('mobile'){
		font-size:12px;
	}
}

@mixin text--bodyvw{
	@extend %u-text--body;
	letter-spacing:0.008em;
	@include respond-to('desktop'){
		font-size:14px;
	}

	font-size:14px;

	@include respond-to('shallow'){
			font-size:12px;
		}

	@include respond-to('mobile'){
		font-size:12px;

	}

	strong{
		font-weight:bold;
	}
}
