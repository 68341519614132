.Downloads{
	position:fixed;
	top:0;
	left:0;
	bottom:0;
	right:0;
	z-index:10;
	background:rgba($Teal,0.95);
	pointer-events: none;
	opacity:0;
	transition:opacity 0.6s ease;

	&.Downloads--active{
		opacity:1;
		pointer-events:all;
	}
}

.Downloads-content *{
	color:white;

	.Content{
		text-align:center;
		max-width:600px;
		margin:0 auto;
	}
}

.Downloads-form{
	min-width:260px;
}
.Downloads .Form{

	max-width:600px;
	margin:0 auto;
	padding-top:30px;
	input{
		display:block;
		background:transparent;
		border-bottom:1px solid;
		@include text--headingvw;
		text-align:center;
		margin:0 auto;
		display:block;
		width:100%;
		padding-bottom:10px;
		margin-bottom:4vw;
	}

	input[type="checkbox"] {
     -webkit-appearance: checkbox !important;
     -moz-appearance: checkbox !important;
     -ms-appearance: checkbox !important;
     -o-appearance: checkbox !important;
     appearance: checkbox !important;
     	display:inline-block;
     	width:20px;
		margin-top:5px;
		vertical-align:top;
		padding-bottom:0;
		& + div{
			display:inline-block;
			width:90%;
			width:calc(100% - 40px);
			padding-left:20px;
			vertical-align: top;
		}
	}

	.Form-error{
		@include text--smallvw;
		text-align:center;
		padding-bottom:5vw;
		color:$Pink;

	}

	.Form-submit{
		margin-top:2.5vw;
		text-align:center;

		.Link{
			&:before{
			
				
				left:-0.5vw;
			}
		}
	}

}

.Downloads-success{
	text-align:center;
	padding:5vw;
}
.Downloads-close{
	position:absolute;
	top:20px;
	right:20px;
	z-index:2;
	transform: rotate(-45deg);
	*{
		color:white;
	}
}
