.Footer{
	height:38vw;
	position:relative;
	padding-top:8vw;

	@include respond-to('portrait'){
		padding-top:20vw;
		height:70vh;
	}

	*{
		color:white;
	}

	.Form{
		input::placeholder{
			color:white;
		}
		padding-bottom:20px;
	}
}

.Footer-contact{
	width:70%;
	margin: 0 auto;
	max-width:400px;

	text-align:center;
}

.Footer-ticker{
	width:100%;
	position:absolute;
	bottom:20px;
	left:0;

	@include respond-to('mobile'){
		bottom:0px;
	}
}
