.Menu-body{
	@include scrollable;

}

.Menu-scroll-content{
	@include respond-to('desktop'){
		margin-left:60px;
	}

	@include respond-to('tablet-down'){
		padding-top:50px;
		
	}
	position:relative;
	height:100%;
}

.Menu{
	

	.Content{
		padding-right:20px;
		width:70%;
	}

	.List--nav{
		padding-bottom:20px;
	}
	.Form{
		padding-right:40px;
	}

	a:hover{
		opacity:0.5;
	}
}
.Menu-venues,
.Menu-foot{
	padding:20px;
}
.Menu-venues{
	padding-bottom:0;
}

.Menu-foot{
	@include respond-to('fix-menu-footer'){
		position:absolute;
		bottom:0;
		left:0;
	}
}
