.Form{

}

.Form--newsletter{
	
	.Form-error{
		@include text--smallvw;
		padding-bottom:5px;
		color:#f73e34;
	}
	input{
		border:1px solid;
		@include text--smallvw;
		padding:5px 10px;
		display:block;
		width:100%;
		opacity:0.3;
		background:transparent;
		transition:opacity 0.4s ease;
		&::placeholder{
			color:black;
		}
		&:focus{
			opacity:1;
		}
	}

	.Form-submit{display:none;}
}

.Form--login{
	position:relative;
	.Form-error{
		@include text--smallvw;
		padding:20px 0;
		color:#F0362A;
		opacity:0.8;
		position:absolute;
		bottom:100%;
		left:0;
	}
	&.Form--loading{
		cursor:wait;
		*{
			pointer-events:none;
			opacity:0.5;
		}
	}
	.Form-field{
		padding-bottom:15px;
	}
	.Form-field-title{
		@include text--introvw;
		padding-bottom:5px;
		//text-transform:uppercase;
	}
	input{

		border-bottom:1px solid;
		@include text--smallvw;
		padding:5px 10px;
		display:block;
		width:100%;
		opacity:0.3;
		background:transparent;
		transition:opacity 0.4s ease;
		&::placeholder{
			color:black;
		}
		&:focus{
			opacity:1;
		}
	}

}