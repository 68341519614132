%u-link--underline{
	border-bottom:1px solid;
	transition:border 0.4s ease;
	
	.Site--no-touch &{
		&:hover{
			border-color:transparent;
		}
	}
}

%u-link--button{
	position:relative;
	display:inline-block;
	padding:10px 10px;
	padding-bottom:10px;
	text-align:center;
	min-width:100px;
	line-height:100%;
	margin-bottom:5px;
	border:1px solid;
	@include text--smallvwAlt;
	transition:all 0.4s ease;
	margin-right:10px;
	//text-transform: capitalize;
	.Html--no-touch &:hover{
	
		background:$Black;
		color:white;
		border-color:$Black;
	
	}
}

%u-link--arrow{
	position:relative;
	display:inline-block;
	&:after{
		@extend %u-iconfont;
		@extend %u-iconfont--arrow-right;
		font-size:25px;
		position:absolute;
		top:50%;
		left:100%;
		margin-left:5px;
		height:30px;
		line-height:30px;
		transform:translate(0,-50%);
		transition:transform 0.4s ease;
	}
	.Html--no-touch &:hover{
		opacity:0.7;
		
		&:after{
			transform:translate(4px,-50%);
		}
	}
}